var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roles"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'Dashboard' }}},[_c('b-icon',{attrs:{"icon":"home"}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Settings' }}},[_vm._v(" Settings ")])],1),_vm._m(0)])]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-3"},[_c('b-field',[_c('b-input',{attrs:{"placeholder":"Pencarian","rounded":"","icon":"search"},on:{"input":_vm.searchRole},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"column is-3 is-offset-6"},[(
          _vm.currentAdmin.role != null && _vm.currentAdmin.role.settingsRoleCreate
        )?_c('b-button',{attrs:{"tag":"router-link","to":{ name: 'RoleCreate' },"type":"is-primary-medium-blue is-pulled-right"}},[_vm._v(" Buat Role Baru ")]):_vm._e()],1),_c('div',{staticClass:"column pb-0 pt-0"},[_c('b-table',{attrs:{"data":_vm.roles,"loading":_vm.loading,"paginated":"","backend-pagination":"","total":_vm.rows,"per-page":_vm.perPage,"aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","backend-sorting":"","default-sort-direction":_vm.defaultSortOrder,"default-sort":[_vm.sortField, _vm.sortOrder]},on:{"page-change":_vm.onPageChange,"sort":_vm.onSort}},[_c('b-table-column',{attrs:{"label":"No"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s((_vm.page - 1) * _vm.perPage + props.index + 1)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"name","sortable":"","label":"Nama Role"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Aksi"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
              props.row.id != 1 &&
                _vm.currentAdmin.role != null &&
                _vm.currentAdmin.role.settingsRoleEdit
            )?_c('router-link',{attrs:{"to":{ name: 'RoleEdit', params: { roleId: props.row.id } }}},[_c('img',{attrs:{"src":require('../../assets/settings/Edit.png'),"alt":"","width":"20","height":"20"}})]):_vm._e(),(
              props.row.id != 1 &&
                _vm.currentAdmin.role != null &&
                _vm.currentAdmin.role.settingsRoleDelete
            )?_c('span',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.deleteRole(props.row.id)}}},[_c('img',{attrs:{"src":require('../../assets/settings/Delete.png'),"alt":"","width":"20","height":"20"}})]):_vm._e()]}}])}),_c('template',{slot:"bottom-left"},[_c('div',{staticClass:"has-text-left"},[_vm._v(" Page "+_vm._s(_vm.page)+" From "+_vm._s(Math.ceil(_vm.rows / _vm.perPage) == '0' ? '1' : Math.ceil(_vm.rows / _vm.perPage))+" ")])])],2)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"has-text-primary-dark-blue"},[_c('span',{staticClass:"px-2"},[_vm._v(" Role Management ")])])}]

export { render, staticRenderFns }