<template>
  <div class="login">
    <section class="modal-card-body">
      <div class="box">
        <img
          :src="require('../assets/logo.png')"
          alt="HargaKurs Logo"
          class="center"
        />
        <h3 class="title has-text-centered has-text-primary-dark-blue">
          Admin Login
        </h3>
        <ValidationObserver v-slot="{ handleSubmit }">
          <ValidationProvider
            rules="required"
            name="Username"
            v-slot="{ errors }"
          >
            <b-field
              label="Username"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              class="mb-3"
            >
              <b-input v-model="username" placeholder="Username"></b-input>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            rules="required"
            name="Password"
            v-slot="{ errors }"
          >
            <b-field
              label="Password"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              class="mb-3"
            >
              <b-input
                v-model="password"
                type="password"
                placeholder="Password"
                password-reveal
              ></b-input>
            </b-field>
          </ValidationProvider>
          <b-field>
            <a
              class="password-remind-link has-text-primary-dark-blue is-pulled-right mb-3"
            >
              Forgot Password?
            </a>
          </b-field>
          <b-button
            class="button is-primary-dark-blue is-large is-fullwidth"
            @click="handleSubmit(login)"
          >
            Login
          </b-button>
        </ValidationObserver>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    login: function() {
      let username = this.username
      let password = this.password
      let redirect = '/after-login'

      this.$store
        .dispatch('login', { username, password })
        .then(() => {
          this.verified = true
          this.$buefy.toast.open({
            message: 'Berhasil Login',
            type: 'is-success',
          })
          this.$router.push({ path: redirect })
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Username atau Password Salah',
            type: 'is-danger',
          })
        })
    },
  },
}
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.login {
  margin: auto;
  width: 50%;
  padding: 100px;
}
</style>
