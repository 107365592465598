<template>
  <div v-if="!isLoading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Settings' }">
            Settings
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <router-link :to="{ name: 'RoleIndex' }">
            Role Management
          </router-link>
        </li>
        <li>
          <span class="px-2">
            Role Management
          </span>
        </li>
      </ul>
    </nav>
    <ValidationObserver v-slot="{ handleSubmit }">
      <div class="columns">
        <div class="column">
          <div style="display: flex; justify-content: flex-end ">
            <div class="buttons">
              <b-button
                class="has-text-primary-medium-blue has-background-primary-dim-blue-transparent"
                style="border: none;font-weight:500"
                @click="cancelAction()"
              >
                Batalkan
              </b-button>

              <b-button
                @click="handleSubmit(save)"
                type="is-primary-medium-blue"
                class="py-3 px-5"
              >
                Simpan
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-multiline is-mobile">
        <div class="column is-12">
          <ValidationProvider
            name="Nama Role"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="columns">
              <div class="column is-3">
                Nama Role
              </div>

              <div class="column is-9">
                <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
                  <b-input v-model="rolePermissions.name"></b-input>
                </b-field>
              </div>
            </div>
          </ValidationProvider>
        </div>

        <div class="column is-12">
          <div class="columns">
            <div class="column is-3">
              Permission
            </div>

            <div class="column is-9">
              <div class="field">
                <b-checkbox v-model="allowAllPermission">
                  Allow All Permission
                </b-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-offset-3-desktop is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              Dashboard
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxDashboard"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.dashboard"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
          </div>
        </div>

        <div class="column is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              DTTOT
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxDTTOT"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.dttotIndex"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.dttotExport"
                type="is-primary-light-blue"
              >
                Export
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.dttotImport"
                type="is-primary-light-blue"
              >
                Import
              </b-switch>
            </div>
          </div>
        </div>

        <div class="column is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              Proliferasi
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxProliferasi"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.proliferasiIndex"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.proliferasiImport"
                type="is-primary-light-blue"
              >
                Import
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.proliferasiExport"
                type="is-primary-light-blue"
              >
                Export
              </b-switch>
            </div>
          </div>
        </div>

        <div class="column is-offset-3-desktop is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              Tenant
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxTenant"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.tenantIndex"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.tenantExport"
                type="is-primary-light-blue"
              >
                Export
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.tenantDetail"
                type="is-primary-light-blue"
              >
                Detail
              </b-switch>
            </div>
          </div>
        </div>

        <div class="column is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              Billing
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxBilling"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.billingIndex"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.billingCreate"
                type="is-primary-light-blue"
              >
                Create
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.billingEdit"
                type="is-primary-light-blue"
              >
                Edit
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.billingDelete"
                type="is-primary-light-blue"
              >
                Delete
              </b-switch>
            </div>
          </div>
        </div>

        <div class="column is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              Setting Role
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxSettingsRole"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsRoleIndex"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsRoleCreate"
                type="is-primary-light-blue"
              >
                Create
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsRoleEdit"
                type="is-primary-light-blue"
              >
                Edit
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsRoleDelete"
                type="is-primary-light-blue"
              >
                Delete
              </b-switch>
            </div>
          </div>
        </div>

        <div class="column is-offset-3-desktop is-half-mobile is-3-desktop">
          <div class="box">
            <div class="permission-title">
              Setting User
              <div class="field" style="margin-left: auto">
                <b-checkbox v-model="checkboxSettingsUser"></b-checkbox>
              </div>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsUserIndex"
                type="is-primary-light-blue"
              >
                Index
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsUserCreate"
                type="is-primary-light-blue"
              >
                Create
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsUserEdit"
                type="is-primary-light-blue"
              >
                Edit
              </b-switch>
            </div>
            <div class="field">
              <b-switch
                v-model="rolePermissions.settingsUserDelete"
                type="is-primary-light-blue"
              >
                Delete
              </b-switch>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: true,
      rolePermissions: {
        name: null,
        dashboard: false,

        dttotIndex: false,
        dttotImport: false,
        dttotExport: false,

        proliferasiIndex: false,
        proliferasiImport: false,
        proliferasiExport: false,

        tenantIndex: false,
        tenantExport: false,
        tenantDetail: false,

        billingIndex: false,
        billingCreate: false,
        billingEdit: false,
        billingDelete: false,

        settingsRoleIndex: false,
        settingsRoleCreate: false,
        settingsRoleEdit: false,
        settingsRoleDelete: false,

        settingsUserIndex: false,
        settingsUserCreate: false,
        settingsUserEdit: false,
        settingsUserDelete: false,
      },
    }
  },
  methods: {
    cancelAction() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah anda yakin membatalkan aksi ini?',
        confirmText: 'Ya',
        cancelText: 'Tidak',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push({ name: 'RoleIndex' })
        },
      })
    },
    save() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah Anda Yakin Menyimpan Data Ini?',
        confirmText: 'Ya',
        cancelText: 'Tidak',
        hasIcon: true,
        onConfirm: () => {
          // console.log(this.rolePermissions)
          if (this.$route.params.roleId == null) {
            this.$store
              .dispatch('storeRole', this.rolePermissions)
              .then(() => {})
          } else {
            console.log(this.rolePermissions)
            this.$store
              .dispatch('updateRole', this.rolePermissions)
              .then(() => {})
          }
        },
      })
    },
  },
  computed: {
    allowAllPermission: {
      get() {
        return (
          this.checkboxDashboard &&
          this.checkboxDTTOT &&
          this.checkboxProliferasi &&
          this.checkboxTenant &&
          this.checkboxBilling &&
          this.checkboxSettingsRole &&
          this.checkboxSettingsUser
        )
      },
      set(value) {
        this.checkboxDashboard = value
        this.checkboxDTTOT = value
        this.checkboxProliferasi = value
        this.checkboxTenant = value
        this.checkboxBilling = value
        this.checkboxSettingsRole = value
        this.checkboxSettingsUser = value
      },
    },
    checkboxDashboard: {
      get() {
        return this.rolePermissions.dashboard
      },
      set(value) {
        this.rolePermissions.dashboard = value
      },
    },
    checkboxDTTOT: {
      get() {
        return (
          this.rolePermissions.dttotIndex &&
          this.rolePermissions.dttotImport &&
          this.rolePermissions.dttotExport
        )
      },
      set(value) {
        this.rolePermissions.dttotIndex = value
        this.rolePermissions.dttotImport = value
        this.rolePermissions.dttotExport = value
      },
    },
    checkboxProliferasi: {
      get() {
        return (
          this.rolePermissions.proliferasiIndex &&
          this.rolePermissions.proliferasiImport &&
          this.rolePermissions.proliferasiExport
        )
      },
      set(value) {
        this.rolePermissions.proliferasiIndex = value
        this.rolePermissions.proliferasiImport = value
        this.rolePermissions.proliferasiExport = value
      },
    },
    checkboxTenant: {
      get() {
        return (
          this.rolePermissions.tenantIndex &&
          this.rolePermissions.tenantExport &&
          this.rolePermissions.tenantDetail
        )
      },
      set(value) {
        this.rolePermissions.tenantIndex = value
        this.rolePermissions.tenantExport = value
        this.rolePermissions.tenantDetail = value
      },
    },
    checkboxBilling: {
      get() {
        return (
          this.rolePermissions.billingIndex &&
          this.rolePermissions.billingCreate &&
          this.rolePermissions.billingEdit &&
          this.rolePermissions.billingDelete
        )
      },
      set(value) {
        this.rolePermissions.billingIndex = value
        this.rolePermissions.billingCreate = value
        this.rolePermissions.billingEdit = value
        this.rolePermissions.billingDelete = value
      },
    },
    checkboxSettingsRole: {
      get() {
        return (
          this.rolePermissions.settingsRoleIndex &&
          this.rolePermissions.settingsRoleCreate &&
          this.rolePermissions.settingsRoleEdit &&
          this.rolePermissions.settingsRoleDelete
        )
      },
      set(value) {
        this.rolePermissions.settingsRoleIndex = value
        this.rolePermissions.settingsRoleCreate = value
        this.rolePermissions.settingsRoleEdit = value
        this.rolePermissions.settingsRoleDelete = value
      },
    },
    checkboxSettingsUser: {
      get() {
        return (
          this.rolePermissions.settingsUserIndex &&
          this.rolePermissions.settingsUserCreate &&
          this.rolePermissions.settingsUserEdit &&
          this.rolePermissions.settingsUserDelete
        )
      },
      set(value) {
        this.rolePermissions.settingsUserIndex = value
        this.rolePermissions.settingsUserCreate = value
        this.rolePermissions.settingsUserEdit = value
        this.rolePermissions.settingsUserDelete = value
      },
    },
  },
  created() {
    if (this.$route.params.roleId != null) {
      this.$store
        .dispatch('getRoleByID', this.$route.params.roleId)
        .then(data => {
          this.rolePermissions = data
          this.isLoading = false
        })
    } else {
      this.isLoading = false
    }
  },
}
</script>

<style scoped>
.permission-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
}
.box {
  background-color: white;
  border-radius: 0.4rem;
  box-shadow: 1px 1px rgba(150, 150, 154, 0.2);
}
</style>
