import ApiService from '@/common/api.service'
import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getAllBilling() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/billing').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllBillingIndex(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/billing?' + params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getBillingData(context, credentials) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('/billing/' + credentials.id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  addNewBilling(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post('/billing/addbilling', {
        name: credentials.name,
        expiry: credentials.expiry,
        price: credentials.price,
        information: credentials.information,
        discount: credentials.discount,
      }).then(
        () => {
          router.push('/billings')
        },
        error => {
          reject(error)
        }
      )
    })
  },
  editBilling(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post('/billing/updatebilling/' + credentials.id, {
        name: credentials.name,
        expiry: credentials.expiry,
        price: credentials.price,
        information: credentials.information,
        discount: credentials.discount,
      }).then(
        () => {
          router.push('/billings')
        },
        error => {
          reject(error)
        }
      )
    })
  },
  deleteBilling(context, credentials) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.post('/billing/delete/' + credentials).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
