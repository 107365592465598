import ApiService from '@/common/api.service'
// import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getAllUserIndex(context, params) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('/admins?' + params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  deleteUser(context, id) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.post('/admins/delete/' + id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  addNewUser(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.postSnakecase('/admins/adduser', credentials).then(
        response => {
          if (response.status == 200) {
            resolve(response)
          }
        },
        error => {
          console.log(error)
          reject(error)
        }
      )
    })
  },
  editUser(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.postSnakecase(
        '/admins/update/' + credentials.id,
        credentials
      ).then(
        response => {
          if (response.status == 200) {
            resolve(response)
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getUserData(context, credentials) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('/admins/get/' + credentials.id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
