import ApiService from '@/common/api.service'
import JwtService from '@/common/jwt.service'
// import router from '@/router'

const state = {
  errors: null,
  message: null,
  admin: { admin: {}, role: {} },
  loggedIn: !!JwtService.getToken(),
}

const getters = {
  currentAdmin(state) {
    return state.admin
  },
  isLoggedIn(state) {
    return state.loggedIn
  },
}

const actions = {
  getCurrentAdmin(context) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/admin/current').then(
        response => {
          if (response.status == 200) {
            context.commit('setAuth', {
              admin: response.data.admin,
              role: response.data.role,
            })
            resolve(response)
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()

      ApiService.post('/admin/login', {
        username: credentials.username,
        password: credentials.password,
      }).then(
        response => {
          if (response.status == 200) {
            context.commit('setAccessToken', response.data.jwtToken)
            context.commit('setAuth', {
              admin: response.data.admin,
              role: response.data.role,
            })
            resolve(response)
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  logout(context) {
    context.commit('purgeAuth')
  },
}

const mutations = {
  setError(state, error) {
    state.errors = error
  },
  setisLoggedIn() {
    state.loggedIn = true
  },
  setAuth(state, admin) {
    state.loggedIn = true
    state.admin = admin
    state.errors = {}
  },
  setAccessToken(state, data) {
    JwtService.saveToken(data)
  },
  purgeAuth(state) {
    state.loggedIn = false
    state.admin = {}
    state.errors = {}
    JwtService.destroyToken()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
