import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import Buefy from 'buefy'
import './common/filters.service.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMinus,
  faPlus,
  faHome,
  faArrowUp,
  faArrowDown,
  faAngleLeft,
  faAngleRight,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './components/validators'
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import './assets/css/main.css'

library.add(
  faMinus,
  faPlus,
  faHome,
  faArrowUp,
  faArrowDown,
  faAngleLeft,
  faAngleRight,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faSearch
)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
