import ApiService from '@/common/api.service'
import router from '@/router'

const state = {}

const getters = {}

const actions = {
  storeExcelDttot(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post('/dttot/importexcel', credentials, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllDTTOT() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/dttot/getAllDTTOT').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllDttotIndex(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/dttot/getAllDTTOT?' + params).then(
        response => {
          // console.log(response.data)
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getDTTOTByID(state, id) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/dttot/get/' + id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
          router.push({ name: 'DTTOTIndex' })
        }
      )
    })
  },
  // updateDTTOT(state, params) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.init()
  //     ApiService.post('/dttot/update/' + params.id, params.dttot).then(
  //       () => {
  //         router.push({ name: 'DTTOTIndex' })
  //       },
  //       error => {
  //         reject(error)
  //       }
  //     )
  //   })
  // },
  // deleteDTTOT(state, id) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.init()
  //     ApiService.post('/dttot/delete/' + id).then(
  //       response => {
  //         resolve(response.data)
  //       },
  //       error => {
  //         reject(error)
  //       }
  //     )
  //   })
  // },
  downloadExcelDttot() {
    ApiService.init()
    ApiService.getFile('/dttot/downloadexcel')
      .then(({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'dttot.xlsx')
        document.body.appendChild(link)
        link.click()

        //for delete file, but cannot work because in used
        // this.$store.dispatch('deleteExportExcel', { name }).then(() => {
        //   this.$buefy.toast.open({
        //     message: 'Download Sukses',
        //     type: 'is-success',
        //   })
        // })
      })
      .catch(error => {
        throw error
      })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
