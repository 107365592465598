import { extend } from 'vee-validate'

import {
  email,
  required,
  alpha_spaces,
  numeric,
  alpha_num,
} from 'vee-validate/dist/rules'

extend('email', {
  ...email,
  message: 'Format email tidak valid!',
})

extend('required', {
  ...required,
  message: '{_field_} tidak boleh kosong',
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Hanya boleh huruf',
})

extend('numeric', {
  ...numeric,
  message: 'Hanya boleh angka',
})
extend('alpha_num', {
  ...alpha_num,
  message: 'Hanya boleh angka atau huruf',
})
extend('password', {
  message:
    '{_field_} harus lebih dari 8 karakter, terdiri Satu Huruf Kapital, Satu Huruf Kecil, Satu Special character contoh (! @ # $ % ^ & *), dan Satu Angka.',
  validate: value => {
    const strong_password = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    ) // regex to check our strong password

    return strong_password.test(value) //Test the regex. Test function returns a true or false value.
  },
})
