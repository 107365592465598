<template>
  <div class="dttot">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            DTTOT & Proliferasi WMD
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Pencarian"
            icon="search"
            v-model="search"
            rounded
            @input="searchDttot"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-three-quarters">
        <div class="button-wrapper">
          <b-button
            @click="downloadExcel"
            v-if="currentAdmin.role != null && currentAdmin.role.dttotExport"
            class="button button-import"
            accept=".xlsx"
          >
            <span class="file">
              <img
                :src="require('../../assets/Export.png')"
                width="17%"
                alt=""
                class="mr-2"
              />
              <span class="file">Export</span>
            </span>
          </b-button>

          <b-input id="fileInput" type="file" style="display:none;" />
          <b-upload
            v-if="currentAdmin.role != null && currentAdmin.role.dttotImport"
            v-model="file"
            @input="importExcel"
            class="button button-import"
            accept=".xlsx"
          >
            <span class="file">
              <img
                :src="require('../../assets/Import.png')"
                width="17%"
                alt=""
                class="mr-2"
              />
              <span class="file">Import</span>
            </span>
          </b-upload>
        </div>
      </div>

      <div class="column is-12" v-if="proliferasi.length > 0">
        Nama
        <strong>{{ search }}</strong>
        Terdaftar di Proliferasi WMD
      </div>

      <div class="column is-12">
        <b-select
          v-model="perPage"
          placeholder="Per-Page"
          :value="perPage"
          @input="getAllDttotIndex()"
        >
          <option
            v-for="option in perPages"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
      </div>

      <div class="column is-12 pb-0 pt-0">
        <b-button class="" style="border:none;box-shadow: none;">
          DTTOT
        </b-button>
        <b-button
          class=""
          style="border:none;box-shadow: none;background-color:transparent"
          v-if="currentAdmin.role != null && currentAdmin.role.proliferasiIndex"
          @click="
            $router.push({
              name: 'ProliferasiIndex',
              params: { search: search },
            })
          "
        >
          Proliferasi WMD
        </b-button>

        <b-table
          class="dttot-table"
          :data="dttot"
          :loading="loading"
          paginated
          backend-pagination
          :total="rows"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column label="No" v-slot="props">
            {{ (page - 1) * perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column field="name" sortable label="Nama" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            field="description"
            sortable
            label="Deskripsi"
            v-slot="props"
          >
            {{ props.row.description }}
          </b-table-column>

          <b-table-column
            field="presumed"
            sortable
            label="Terduga"
            v-slot="props"
          >
            {{ props.row.presumed }}
          </b-table-column>

          <b-table-column
            field="densus_code"
            sortable
            label="Kode Densus"
            v-slot="props"
          >
            {{ props.row.densusCode }}
          </b-table-column>

          <b-table-column
            field="birthplace"
            sortable
            label="Tempat Lahir"
            v-slot="props"
          >
            {{ props.row.birthplace }}
          </b-table-column>

          <b-table-column
            field="birthdate"
            sortable
            label="Tanggal Lahir"
            v-slot="props"
          >
            {{ props.row.birthdate }}
          </b-table-column>

          <b-table-column
            field="citizenship"
            sortable
            label="Warga Negara"
            v-slot="props"
          >
            {{ props.row.citizenship }}
          </b-table-column>

          <b-table-column
            field="address"
            sortable
            label="Alamat"
            v-slot="props"
            width="17%"
          >
            {{ props.row.address }}
          </b-table-column>

          <template slot="bottom-left">
            <div class="has-text-left">
              Page {{ page }} From
              {{
                Math.ceil(rows / perPage) == '0'
                  ? '1'
                  : Math.ceil(rows / perPage)
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      file: {},
      dttot: [],
      search: '',
      total: 0,
      loading: false,
      sortField: '',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 10,

      proliferasi: [],

      perPages: [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '25', value: '25' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
    }
  },
  created() {
    this.getAllDttotIndex(this.$route.params.search)
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['currentAdmin']),
    rows() {
      return this.total
    },
  },
  methods: {
    searchDttot: debounce(function() {
      this.getAllDttotIndex()
    }, 500),
    getAllDttotIndex: function(data) {
      if (data != null || data != undefined) {
        this.search = data
        data = null
      }
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
        `search=${this.search}`,
      ].join('&')
      this.loading = true
      this.$store.dispatch('getAllDttotIndex', params).then(response => {
        if (response.data != null && response.total != 0) {
          this.dttot = response.data
          this.proliferasi = []
        } else if (response.data != null && response.total == 0) {
          this.dttot = []
          this.proliferasi = response.data
        } else {
          this.dttot = []
          this.proliferasi = []
        }
        this.total = response.total
        this.loading = false
      })
    },
    onPageChange(page) {
      this.page = page
      this.getAllDttotIndex()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getAllDttotIndex()
    },
    importExcel() {
      this.loading = true
      var files = this.file
      let formData = new FormData()
      formData.append('file', files)
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1])
      // }
      this.$store
        .dispatch('storeExcelDttot', formData)
        .then(() => {
          this.loading = false
          this.$buefy.toast.open({
            message: 'Import Berhasil!',
            type: 'is-success',
          })
          this.getAllDttotIndex()
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Error Import!',
            type: 'is-danger',
          })
          this.loading = false
        })
    },
    downloadExcel() {
      this.$store.dispatch('downloadExcelDttot')
    },
  },
}
</script>

<style>
.button-wrapper {
  text-align: right;
}
.button-import {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}

.dttot-table .table-wrapper {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
</style>
