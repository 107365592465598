<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Settings' }">
            Settings
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Users' }">
            Users
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2" v-if="this.$route.path.includes('/adduser')">
            Buat User Baru
          </span>
          <span class="px-2" v-else>
            Edit User
          </span>
        </li>
      </ul>
    </nav>

    <ValidationObserver v-slot="{ handleSubmit }" v-if="!changePassword">
      <form @submit.prevent="handleSubmit(save)">
        <div class="form">
          <div class="box-button mb-4">
            <b-button
              class="is-pulled-right"
              type="is-primary-medium-blue"
              native-type="submit"
            >
              Simpan
            </b-button>
            <b-button
              class="is-pulled-right mr-2 has-text-primary-medium-blue has-background-primary-dim-blue-transparent has-text-weight-medium"
              variant="outline-primary"
              style="border:none;"
              @click="cancelAction"
            >
              Batalkan
            </b-button>
            <b-button
              class="is-pulled-right has-text-primary-medium-blue has-background-primary-dim-blue-transparent"
              variant="outline-primary"
              style="border:none;"
              v-if="!$route.path.includes('/adduser')"
              @click="changePassword = !changePassword"
            >
              Ubah Password
            </b-button>
          </div>
          <div class="form-input  my-1 pb-4">
            <ValidationProvider
              name="Nama"
              rules="required|alpha_spaces"
              v-slot="{ errors }"
            >
              <b-field class=" my-4">
                <template slot="label">
                  <span class="is-size-7">
                    Nama
                  </span>
                </template>
                <b-input
                  v-model="formUser.name"
                  placeholder="Nama"
                  type="text"
                ></b-input>
              </b-field>
              <p class=" has-text-red" v-if="errors != null">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
            <ValidationProvider
              name="Username"
              rules="required|alpha_num"
              v-slot="{ errors }"
            >
              <b-field class=" my-4">
                <template slot="label">
                  <span class="is-size-7">
                    Username
                  </span>
                </template>
                <b-input
                  placeholder="Masukkan Username"
                  v-model="formUser.username"
                ></b-input>
              </b-field>
              <p class=" has-text-red" v-if="errors != null">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
            <ValidationProvider
              name="Peran"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field class=" my-4">
                <template slot="label">
                  <span class="is-size-7">
                    Peran
                  </span>
                </template>
                <b-select
                  v-model="formUser.roleId"
                  placeholder="Pilih Peran"
                  expanded
                  @input="changeRoleId"
                  :value="formUser.roleId"
                >
                  <option
                    v-for="option in role"
                    :value="option.value"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </b-select>
              </b-field>
              <p class=" has-text-red" v-if="errors != null">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
            <ValidationProvider
              v-if="$route.path.includes('/adduser')"
              name="Password"
              rules="required|password"
              v-slot="{ errors }"
            >
              <b-field class=" my-4">
                <template slot="label">
                  <span class="is-size-7">
                    Password
                  </span>
                </template>
                <b-input
                  placeholder="Masukkan Password"
                  v-model="formUser.password"
                  type="password"
                  password-reveal
                ></b-input>
              </b-field>
              <p class=" has-text-red" v-if="errors != null">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <ValidationObserver v-slot="{ handleSubmit }" v-if="changePassword">
      <div class="columns">
        <div class="column">
          <div class="buttons" style="justify-content: flex-end">
            <b-button
              class="is-pulled-right has-text-primary-medium-blue has-background-primary-dim-blue-transparent"
              variant="outline-primary"
              style="border:none;"
              @click="changePassword = !changePassword"
            >
              Ubah Profil
            </b-button>
            <b-button
              class="has-text-primary-medium-blue has-background-primary-dim-blue-transparent has-text-weight-medium"
              style="border: none;"
              @click="cancelAction()"
            >
              Batalkan
            </b-button>

            <b-button
              @click="handleSubmit(savePassword)"
              type="is-primary-medium-blue"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </div>
      <ValidationProvider
        rules="required|password"
        name="Password"
        v-slot="{ errors }"
      >
        <b-field
          class="my-4"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <template slot="label">
            <span class="is-size-7">
              Password
            </span>
          </template>
          <b-input
            placeholder="Masukkan Password"
            v-model="formUser.password"
            type="password"
            password-reveal
          ></b-input>
        </b-field>
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: [{ text: 'Select One', value: null, disabled: true }],
      formUser: {
        name: '',
        password: '',
        username: '',
        roleId: 1,
      },
      changePassword: false,
    }
  },
  created() {
    if (this.$route.path !== '/settings/users/adduser') {
      this.getUserData(this.$route.params.usersid)
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllRole').then(data => {
      for (var i in data) {
        var obj = { text: '', value: '' }
        obj.text = data[i].name
        obj.value = data[i].id
        this.role.push(obj)
      }
    })
  },
  methods: {
    getUserData(id) {
      this.$store.dispatch('getUserData', { id }).then(data => {
        this.formUser = data
      })
    },
    save() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah Anda Yakin Menyimpan Data Ini?',
        confirmText: 'Ya',
        hasIcon: true,
        onConfirm: () => {
          if (this.$route.path === '/settings/users/adduser') {
            let name = this.formUser.name
            let username = this.formUser.username
            let password = this.formUser.password
            let roleId = this.formUser.roleId
            this.$store
              .dispatch('addNewUser', {
                name,
                username,
                password,
                roleId,
              })
              .then(() => {
                this.$buefy.toast.open({
                  message: 'Pembuatan user berhasil',
                  type: 'is-success',
                })
                this.$router.push({ path: '/settings/users' })
              })
              .catch(() => {
                this.$buefy.toast.open({
                  message: 'Username sudah terdaftar dalam sistem!',
                  type: 'is-danger',
                })
              })
          } else {
            let name = this.formUser.name
            let username = this.formUser.username
            let id = this.$route.params.usersid
            let roleId = this.formUser.roleId
            this.$store
              .dispatch('editUser', {
                name,
                username,
                id,
                roleId,
              })
              .then(() => {
                this.$buefy.toast.open({
                  message: 'Edit user berhasil',
                  type: 'is-success',
                })
                this.$router.push({ path: '/settings/users' })
              })
              .catch(() => {
                this.$buefy.toast.open({
                  message: 'Username sudah terdaftar dalam sistem!',
                  type: 'is-danger',
                })
              })
          }
        },
      })
    },
    savePassword() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah Anda Yakin Menyimpan Data Ini?',
        confirmText: 'Ya',
        hasIcon: true,
        onConfirm: () => {
          let name = this.formUser.name
          let username = this.formUser.username
          let id = this.$route.params.usersid
          let password = this.formUser.password
          let roleId = this.formUser.roleId
          this.$store
            .dispatch('editUser', {
              name,
              username,
              password,
              id,
              roleId,
            })
            .then(() => {
              this.$buefy.toast.open({
                message: 'Ubah Password Berhasil',
                type: 'is-success',
              })
              this.$router.push({ path: '/settings/users' })
            })
        },
      })
    },
    cancelAction() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah anda yakin membatalkan aksi ini?',
        confirmText: 'Ya',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push('/settings/users')
        },
      })
    },
    changeRoleId(value) {
      this.formUser.roleId = value
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
  computed: {
    currentValue() {
      var selected = this.role.find(
        option => option.value === this.formUser.roleId
      )
      return selected
    },
  },
}
</script>

<style scoped>
.form-input {
  border-radius: 0.4rem;
}
.form {
  display: flex;
  flex-direction: column;
}
</style>
