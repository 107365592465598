<template>
  <div class="dashboard" v-if="!isLoading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>

        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Dashboard
          </span>
        </li>
      </ul>
    </nav>
    <div class="columns pl-3 is-multiline">
      <div class="column is-3 is-size-6">
        <div class="card" style="height:100px;">
          <div class="columns" style="height:100%;padding:0;margin:0">
            <div class="column is-2" style="background-color:Blue"></div>
            <div class="column is-10">
              Pendapatan Total Bulan Ini
              <br />
              {{ totalRevenue.total | toCurrency }}
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3 is-size-6">
        <div class="card" style="height:100px;">
          <div class="columns" style="height:100%;padding:0;margin:0">
            <div class="column is-2" style="background-color:Green"></div>
            <div class="column is-10">
              Total Tenant Aktif
              <br />
              {{ totalActiveTenant.total }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns pl-3">
      <div class="column is-12">
        <div class="is-size-4 mb-2">Tenant dengan Sisa Billing Terdekat</div>
        <div>
          <b-table :data="tenantList" sortable>
            <b-table-column
              v-slot="props"
              field="companyName"
              sortable
              label="Nama Tenant"
              cellClass="ml-3"
            >
              {{ props.row.companyName }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="billingName"
              sortable
              label="Nama Billing"
            >
              {{ props.row.billingName }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="remainDay"
              sortable
              label="Sisa Billing"
              cellClass="ml-3"
            >
              {{ props.row.remainDay }} Hari Lagi
            </b-table-column>

            <b-table-column
              label="Aksi"
              v-slot="props"
              v-if="currentAdmin.role != null && currentAdmin.role.tenantDetail"
            >
              <router-link
                :to="`/tenant/${props.row.id}`"
                v-if="
                  currentAdmin.role != null && currentAdmin.role.tenantDetail
                "
              >
                <img
                  :src="require('../../assets/Edit.png')"
                  alt=""
                  width="20"
                  height="20"
                />
              </router-link>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      totalActiveTenant: {},
      totalRevenue: {},
      tenantList: [
        {
          id: 1,
          companyName: 'Siapa',
          billingName: 'Pembelian',
          remainDay: '3 hari lagi',
        },

        {
          id: 2,
          companyName: 'Siapa',
          billingName: 'Penjualan',
          remainDay: '3 hari lagi',
        },

        {
          id: 3,
          companyName: 'Siapa',
          billingName: 'Penjualan',
          remainDay: '3 hari lagi',
        },

        {
          id: 4,
          companyName: 'Siapa',
          billingName: 'Penjualan',
          remainDay: '3 hari lagi',
        },

        {
          id: 5,
          companyName: 'Siapa',
          billingName: 'Pembelian',
          remainDay: '3 hari lagi',
        },
        {
          id: 6,
          companyName: 'Siapa',
          billingName: 'Pembelian',
          remainDay: '3 hari lagi',
        },

        {
          id: 7,
          companyName: 'Siapa',
          billingName: 'Penjualan',
          remainDay: '3 hari lagi',
        },

        {
          id: 8,
          companyName: 'Siapa',
          billingName: 'Penjualan',
          remainDay: '3 hari lagi',
        },

        {
          id: 9,
          companyName: 'Siapa',
          billingName: 'Pembelian',
          remainDay: '3 hari lagi',
        },

        {
          id: 10,
          companyName: 'Siapa',
          billingName: 'Pembelian',
          remainDay: '3 hari lagi',
        },
      ],
    }
  },
  methods: {
    dotSeparator: function(x) {
      if (x == null) {
        x = 0
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    getActiveCompanyClosestBilling: function() {
      this.$store
        .dispatch('getActiveCompanyClosestBilling')
        .then(data => {
          if (data != null) {
            this.tenantList = data
          }
        })
        .catch(error => {
          throw error
        })
    },
    getTotalActiveCompany: function() {
      this.$store
        .dispatch('getTotalActiveCompany')
        .then(data => {
          if (data != null) {
            this.totalActiveTenant = data
          }
        })
        .catch(error => {
          throw error
        })
    },
    getThisMonthTotalRevenue: function() {
      this.$store
        .dispatch('getThisMonthTotalRevenue')
        .then(data => {
          if (data != null) {
            this.totalRevenue = data
          }
        })
        .catch(error => {
          throw error
        })
    },
  },
  computed: {
    ...mapGetters(['currentAdmin']),
  },
  created() {
    this.getActiveCompanyClosestBilling()
    this.getTotalActiveCompany()
    this.getThisMonthTotalRevenue()
    this.isLoading = false
  },
}
</script>
<style scoped></style>
