var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'Dashboard' }}},[_c('b-icon',{attrs:{"icon":"home"}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Settings' }}},[_vm._v(" Settings ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Users' }}},[_vm._v(" Users ")])],1),_c('li',{staticClass:"has-text-primary-dark-blue"},[(this.$route.path.includes('/adduser'))?_c('span',{staticClass:"px-2"},[_vm._v(" Buat User Baru ")]):_c('span',{staticClass:"px-2"},[_vm._v(" Edit User ")])])])]),(!_vm.changePassword)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"box-button mb-4"},[_c('b-button',{staticClass:"is-pulled-right",attrs:{"type":"is-primary-medium-blue","native-type":"submit"}},[_vm._v(" Simpan ")]),_c('b-button',{staticClass:"is-pulled-right mr-2 has-text-primary-medium-blue has-background-primary-dim-blue-transparent has-text-weight-medium",staticStyle:{"border":"none"},attrs:{"variant":"outline-primary"},on:{"click":_vm.cancelAction}},[_vm._v(" Batalkan ")]),(!_vm.$route.path.includes('/adduser'))?_c('b-button',{staticClass:"is-pulled-right has-text-primary-medium-blue has-background-primary-dim-blue-transparent",staticStyle:{"border":"none"},attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.changePassword = !_vm.changePassword}}},[_vm._v(" Ubah Password ")]):_vm._e()],1),_c('div',{staticClass:"form-input  my-1 pb-4"},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:" my-4"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Nama ")])]),_c('b-input',{attrs:{"placeholder":"Nama","type":"text"},model:{value:(_vm.formUser.name),callback:function ($$v) {_vm.$set(_vm.formUser, "name", $$v)},expression:"formUser.name"}})],2),(errors != null)?_c('p',{staticClass:" has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Username","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:" my-4"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Username ")])]),_c('b-input',{attrs:{"placeholder":"Masukkan Username"},model:{value:(_vm.formUser.username),callback:function ($$v) {_vm.$set(_vm.formUser, "username", $$v)},expression:"formUser.username"}})],2),(errors != null)?_c('p',{staticClass:" has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Peran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:" my-4"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Peran ")])]),_c('b-select',{attrs:{"placeholder":"Pilih Peran","expanded":"","value":_vm.formUser.roleId},on:{"input":_vm.changeRoleId},model:{value:(_vm.formUser.roleId),callback:function ($$v) {_vm.$set(_vm.formUser, "roleId", $$v)},expression:"formUser.roleId"}},_vm._l((_vm.role),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],2),(errors != null)?_c('p',{staticClass:" has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),(_vm.$route.path.includes('/adduser'))?_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:" my-4"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Password ")])]),_c('b-input',{attrs:{"placeholder":"Masukkan Password","type":"password","password-reveal":""},model:{value:(_vm.formUser.password),callback:function ($$v) {_vm.$set(_vm.formUser, "password", $$v)},expression:"formUser.password"}})],2),(errors != null)?_c('p',{staticClass:" has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}):_vm._e()],1)])])]}}],null,false,904514470)}):_vm._e(),(_vm.changePassword)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons",staticStyle:{"justify-content":"flex-end"}},[_c('b-button',{staticClass:"is-pulled-right has-text-primary-medium-blue has-background-primary-dim-blue-transparent",staticStyle:{"border":"none"},attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.changePassword = !_vm.changePassword}}},[_vm._v(" Ubah Profil ")]),_c('b-button',{staticClass:"has-text-primary-medium-blue has-background-primary-dim-blue-transparent has-text-weight-medium",staticStyle:{"border":"none"},on:{"click":function($event){return _vm.cancelAction()}}},[_vm._v(" Batalkan ")]),_c('b-button',{attrs:{"type":"is-primary-medium-blue"},on:{"click":function($event){return handleSubmit(_vm.savePassword)}}},[_vm._v(" Simpan ")])],1)])]),_c('ValidationProvider',{attrs:{"rules":"required|password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"my-4",attrs:{"type":{ 'is-danger': errors[0] },"message":errors}},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Password ")])]),_c('b-input',{attrs:{"placeholder":"Masukkan Password","type":"password","password-reveal":""},model:{value:(_vm.formUser.password),callback:function ($$v) {_vm.$set(_vm.formUser, "password", $$v)},expression:"formUser.password"}})],2)]}}],null,true)})]}}],null,false,2630123041)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }