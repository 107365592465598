import ApiService from '@/common/api.service'
// import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getRevenueByDate(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/storemidtrans/revenue-by-date?' + params).then(
        response => {
          // console.log(response.data)
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAddOnSales(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/reporting/addon/getAddOnSales?' + params).then(
        response => {
          // console.log(response.data)
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  downloadExcelAddOn() {
    ApiService.init()
    ApiService.getFile('/reporting/addon/downloadexcel')
      .then(({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'addon sales.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        throw error
      })
  },
  getBillingSales(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/reporting/billing/getBillingSales?' + params).then(
        response => {
          // console.log(response.data)
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  downloadExcelBilling() {
    ApiService.init()
    ApiService.getFile('/reporting/billing/downloadexcel')
      .then(({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'billing sales.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        throw error
      })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
