import ApiService from '@/common/api.service'
// import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getCompanyManagement(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/company/tenantmanagement?' + params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getCompanyManagementDetail(context, credentials) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('/company/tenantmanagement/' + credentials.id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAddOnCompanyManagementDetail(state, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get(
        '/company/tenantmanagement/addon/' +
          credentials.id +
          '?' +
          credentials.params
      ).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  downloadExcelTenant() {
    ApiService.init()
    ApiService.getFile('/company/tenantmanagement/downloadexcel')
      .then(({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'tenant.xlsx')
        document.body.appendChild(link)
        link.click()

        //for delete file, but cannot work because in used
        // this.$store.dispatch('deleteExportExcel', { name }).then(() => {
        //   this.$buefy.toast.open({
        //     message: 'Download Sukses',
        //     type: 'is-success',
        //   })
        // })
      })
      .catch(error => {
        throw error
      })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
