<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Settings' }">
            Settings
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Users
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Pencarian"
            v-model="search"
            icon="search"
            rounded
            @input="searchUser"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-3 is-offset-6">
        <b-button
          tag="router-link"
          :to="{ name: 'AddUser' }"
          type="is-primary-medium-blue is-pulled-right"
          v-if="
            currentAdmin.role != null && currentAdmin.role.settingsUserCreate
          "
        >
          Buat User Baru
        </b-button>
      </div>

      <div class="column pb-0 pt-0">
        <b-table
          :data="users"
          :loading="loading"
          paginated
          backend-pagination
          :total="rows"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column label="No" v-slot="props">
            {{ (page - 1) * perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column field="name" sortable label="Nama" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            field="username"
            sortable
            label="Username"
            v-slot="props"
          >
            {{ props.row.username }}
          </b-table-column>

          <b-table-column field="role" sortable label="Role" v-slot="props">
            {{ props.row.role }}
          </b-table-column>

          <b-table-column label="Aksi" v-slot="props">
            <router-link
              v-if="
                currentAdmin.role != null && currentAdmin.role.settingsUserEdit
              "
              :to="`/settings/users/${props.row.id}`"
            >
              <img
                :src="require('../../assets/settings/Edit.png')"
                alt=""
                width="20"
                height="20"
              />
            </router-link>
            <span
              v-if="
                currentAdmin.role != null &&
                  currentAdmin.role.settingsUserDelete
              "
              @click="deleteUser(props.row.id)"
              class="ml-2"
              style="cursor:pointer;"
            >
              <img
                :src="require('../../assets/settings/Delete.png')"
                alt=""
                width="20"
                height="20"
              />
            </span>
          </b-table-column>
          <template slot="bottom-left">
            <div class="has-text-left">
              Page {{ page }} From
              {{
                Math.ceil(rows / perPage) == '0'
                  ? '1'
                  : Math.ceil(rows / perPage)
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      users: [],
      total: 0,
      loading: false,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      page: 1,
      perPage: 8,
      search: '',
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['currentAdmin']),
    rows() {
      return this.total
    },
  },
  created() {
    this.getAllUserIndex()
  },
  methods: {
    searchUser: debounce(function() {
      this.getAllUserIndex()
    }, 500),
    getAllUserIndex: function() {
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
        `search=${this.search}`,
      ].join('&')
      this.loading = true

      this.$store.dispatch('getAllUserIndex', params).then(response => {
        if (response.data != null) {
          this.users = response.data
        } else {
          this.users = []
        }
        this.total = response.total
        this.loading = false
      })
    },
    onPageChange(page) {
      this.page = page
      this.getAllUserIndex()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getAllUserIndex()
    },
    deleteUser(id) {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah Anda Yakin Menghapus Data Ini?',
        confirmText: 'Ya',
        cancelText: 'Tidak',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          if (id) {
            this.$store
              .dispatch('deleteUser', id)
              .then(() => {
                this.$buefy.toast.open({
                  message: 'Berhasil Hapus User',
                  type: 'is-success',
                })
                this.getAllUserIndex()
              })
              .catch(err => {
                console.log(err)
                this.$buefy.toast.open({
                  message: 'Gagal Hapus User',
                  type: 'is-danger',
                })
              })
          }
        },
      })
    },
  },
}
</script>
