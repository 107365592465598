<template>
  <div class="proliferasi">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            DTTOT & Proliferasi WMD
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Pencarian"
            icon="search"
            v-model="search"
            rounded
            @input="searchProliferasi"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-three-quarters">
        <div class="button-wrapper">
          <b-button
            @click="downloadExcel"
            class="button button-import"
            accept=".xlsx"
            v-if="
              currentAdmin.role != null && currentAdmin.role.proliferasiExport
            "
          >
            <span class="file">
              <img
                :src="require('../../assets/Export.png')"
                width="17%"
                alt=""
                class="mr-2"
              />
              <span class="file">Export</span>
            </span>
          </b-button>

          <b-input id="fileInput" type="file" style="display:none;" />
          <b-upload
            v-model="file"
            @input="importExcel"
            v-if="
              currentAdmin.role != null && currentAdmin.role.proliferasiImport
            "
            class="button button-import"
            accept=".xlsx"
          >
            <span class="file">
              <img
                :src="require('../../assets/Import.png')"
                width="17%"
                alt=""
                class="mr-2"
              />
              <span class="file">Import</span>
            </span>
          </b-upload>
        </div>
      </div>

      <div class="column is-12" v-if="dttot.length > 0">
        Nama
        <strong>{{ search }}</strong>
        Terdaftar di Dttot
      </div>

      <div class="column is-12">
        <b-select
          v-model="perPage"
          placeholder="Per-Page"
          :value="perPage"
          @input="getAllProliferasiIndex()"
        >
          <option
            v-for="option in perPages"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
      </div>

      <div class="column is-12 pb-0 pt-0">
        <b-button
          class=""
          style="border:none;background-color:transparent;box-shadow: none;"
          v-if="currentAdmin.role != null && currentAdmin.role.dttotIndex"
          @click="
            $router.push({
              name: 'DTTOTIndex',
              params: { search: search },
            })
          "
        >
          DTTOT
        </b-button>
        <b-button class="" style="border:none;box-shadow: none;">
          Proliferasi WMD
        </b-button>
        <b-table
          class="proliferasi-table"
          :data="proliferasi"
          :loading="loading"
          paginated
          backend-pagination
          :total="rows"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column label="No" v-slot="props">
            {{ (page - 1) * perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column field="name" sortable label="Nama" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="title" sortable label="Gelar" v-slot="props">
            {{ props.row.title }}
          </b-table-column>

          <b-table-column field="job" sortable label="Pekerjaan" v-slot="props">
            {{ props.row.job }}
          </b-table-column>

          <b-table-column
            field="birthdate"
            sortable
            label="Tanggal Lahir"
            v-slot="props"
          >
            {{ props.row.birthdate }}
          </b-table-column>

          <b-table-column
            field="birthplace"
            sortable
            label="Tempat Lahir"
            v-slot="props"
          >
            {{ props.row.birthplace }}
          </b-table-column>

          <b-table-column
            field="firstAlias"
            sortable
            label="Alias 1"
            v-slot="props"
          >
            {{ props.row.firstAlias }}
          </b-table-column>

          <b-table-column
            field="secondAlias"
            sortable
            label="Alias 2"
            v-slot="props"
          >
            {{ props.row.secondAlias }}
          </b-table-column>

          <b-table-column
            field="thirdAlias"
            sortable
            label="Alias 3"
            v-slot="props"
          >
            {{ props.row.thirdAlias }}
          </b-table-column>

          <b-table-column
            field="fourthAlias"
            sortable
            label="Alias 4"
            v-slot="props"
          >
            {{ props.row.fourthAlias }}
          </b-table-column>

          <b-table-column
            field="fifthAlias"
            sortable
            label="Alias 5"
            v-slot="props"
          >
            {{ props.row.fifthAlias }}
          </b-table-column>

          <b-table-column
            field="citizenship"
            sortable
            label="Kewarganegaraan"
            v-slot="props"
          >
            {{ props.row.citizenship }}
          </b-table-column>

          <b-table-column
            field="pasportNumber"
            sortable
            label="Nomor Paspor"
            v-slot="props"
            width="17%"
          >
            {{ props.row.pasportNumber }}
          </b-table-column>

          <b-table-column
            field="identityNumber"
            sortable
            label="Nomor Identitas"
            v-slot="props"
            width="17%"
          >
            {{ props.row.identityNumber }}
          </b-table-column>

          <b-table-column
            field="address"
            sortable
            label="Alamat"
            v-slot="props"
            width="17%"
          >
            {{ props.row.address }}
          </b-table-column>

          <b-table-column
            field="otherInfo"
            sortable
            label="Informasi Lain"
            v-slot="props"
            width="17%"
          >
            {{ props.row.otherInfo }}
          </b-table-column>

          <template slot="bottom-left">
            <div class="has-text-left">
              Page {{ page }} From
              {{
                Math.ceil(rows / perPage) == '0'
                  ? '1'
                  : Math.ceil(rows / perPage)
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      file: {},
      proliferasi: [],
      search: '',
      total: 0,
      loading: false,
      sortField: '',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 10,

      dttot: [],

      perPages: [
        { text: '5', value: '5' },
        { text: '10', value: '10' },
        { text: '25', value: '25' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
    }
  },
  created() {
    this.getAllProliferasiIndex(this.$route.params.search)
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['currentAdmin']),
    rows() {
      return this.total
    },
  },
  methods: {
    searchProliferasi: debounce(function() {
      this.getAllProliferasiIndex()
    }, 500),
    getAllProliferasiIndex: function(data) {
      if (data != null || data != undefined) {
        this.search = data
        data = null
      }
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
        `search=${this.search}`,
      ].join('&')
      this.loading = true
      this.$store.dispatch('getAllProliferasiIndex', params).then(response => {
        if (response.data != null && response.total != 0) {
          this.proliferasi = response.data
          this.dttot = []
        } else if (response.data != null && response.total == 0) {
          this.proliferasi = []
          this.dttot = response.data
        } else {
          this.proliferasi = []
          this.dttot = []
        }
        this.total = response.total
        this.loading = false
      })
    },
    onPageChange(page) {
      this.page = page
      this.getAllProliferasiIndex()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getAllProliferasiIndex()
    },
    importExcel() {
      this.loading = true
      var files = this.file
      let formData = new FormData()
      formData.append('file', files)
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1])
      // }
      this.$store
        .dispatch('storeExcelProliferasi', formData)
        .then(() => {
          this.loading = false
          this.$buefy.toast.open({
            message: 'Import Berhasil!',
            type: 'is-success',
          })
          this.getAllProliferasiIndex()
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Error Import!',
            type: 'is-danger',
          })
          this.loading = false
        })
    },
    downloadExcel() {
      this.$store.dispatch('downloadExcelProliferasi')
    },
  },
}
</script>

<style>
.button-wrapper {
  text-align: right;
}
.button-import {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}
.proliferasi-table .table-wrapper {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
</style>
