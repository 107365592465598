import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import DTTOTIndex from '../views/DTTOT/Index.vue'
import ProliferasiIndex from '../views/Proliferasi/Index.vue'
import BillingIndex from '../views/Billing/Index.vue'
import store from '../store/index.js'
import RoleIndex from '../views/Role/Index.vue'
import FormRole from '../views/Role/FormRole.vue'
import Settings from '../views/Settings.vue'
import UserIndex from '../views/Users/Index.vue'
import FormUser from '../views/Users/FormUser.vue'
import Dashboard from '../views/Dashboard/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/after-login',
    component: () => import('@/views/AfterLogin.vue'),
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: Dashboard,
        name: 'Dashboard',
        meta: {
          requiresAuth: true,
          name: 'Dashboard',
        },
      },

      {
        path: '/dttot&proliferasi',
        name: 'DTTOTIndex',
        component: DTTOTIndex,
        meta: {
          requiresAuth: true,
          name: 'DTTOT & Proliferasi WMD',
          middleware: {
            auth: 'dttotIndex',
          },
        },
      },
      {
        path: '/dttot&proliferasi/proliferasi',
        name: 'ProliferasiIndex',
        component: ProliferasiIndex,
        meta: {
          requiresAuth: true,
          name: 'DTTOT & Proliferasi WMD',
          middleware: {
            auth: 'proliferasiIndex',
          },
        },
      },
      {
        path: '/billings',
        name: 'BillingIndex',
        component: BillingIndex,
        meta: {
          requiresAuth: true,
          name: 'Billings',
          middleware: {
            auth: 'billingIndex',
          },
        },
      },
      {
        path: '/billings/addbilling',
        name: 'AddBilling',
        component: () => import('../views/Billing/BillingForm.vue'),
        meta: {
          name: 'Billings',
          requiresAuth: true,
          middleware: {
            auth: 'billingCreate',
          },
        },
      },
      {
        path: '/billings/:billingid',
        name: 'EditBilling',
        component: () => import('../views/Billing/BillingForm.vue'),
        meta: {
          name: 'Billings',
          requiresAuth: true,
          middleware: {
            auth: 'billingEdit',
          },
        },
      },
      {
        path: '/tenant',
        name: 'TenantIndex',
        component: () => import('../views/Company/Index.vue'),
        meta: {
          requiresAuth: true,
          name: 'Tenant Management',
          middleware: {
            auth: 'tenantIndex',
          },
        },
      },
      {
        path: '/tenant/:companyid',
        name: 'EditTenant',
        component: () => import('../views/Company/Detail.vue'),
        meta: {
          requiresAuth: true,
          name: 'Tenant Management',
          middleware: {
            auth: 'tenantDetail',
          },
        },
      },
      {
        path: '/addons',
        name: 'AddOnsIndex',
        component: () => import('../views/AddOn/Index.vue'),
        meta: {
          requiresAuth: true,
          name: 'Add-Ons Management',
        },
      },
      {
        path: '/addons/addnewaddon',
        name: 'AddAddOns',
        component: () => import('../views/AddOn/AddOnForm.vue'),
        meta: {
          requiresAuth: true,
          name: 'Add-Ons Management',
          // middleware: {
          //   auth: 'NewAddOn',
          // },
        },
      },
      {
        path: '/addons/:addonid',
        name: 'EditAddOns',
        component: () => import('../views/AddOn/AddOnForm.vue'),
        meta: {
          requiresAuth: true,
          name: 'Add-Ons Management',
          // middleware: {
          //   auth: 'AddOnEdit',
          // },
        },
      },

      {
        path: '/reporting',
        name: 'ReportIndex',
        redirect: '/reporting/revenue',
        meta: {
          name: 'Report',
          onlyAdmin: true,
          requiresAuth: true,
        },
      },
      {
        path: '/reporting/revenue',
        name: 'ReportRevenue',
        component: () => import('../views/Reporting/Revenue.vue'),
        meta: {
          name: 'Reporting',
          onlyAdmin: true,
          requiresAuth: true,
        },
      },
      {
        path: '/reporting/addon',
        component: () => import('../views/Reporting/AddOn.vue'),
        name: 'ReportAddon',
        meta: {
          name: 'Reporting',
          onlyAdmin: true,
          requiresAuth: true,
        },
      },
      {
        path: '/reporting/billing',
        component: () => import('../views/Reporting/Billing.vue'),
        name: 'ReportBilling',
        meta: {
          name: 'Reporting',
          onlyAdmin: true,
          requiresAuth: true,
        },
      },

      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
          name: 'Settings',
          requiresAuth: true,
        },
      },
      {
        path: '/settings/role',
        name: 'RoleIndex',
        component: RoleIndex,
        meta: {
          name: 'Settings',
          requiresAuth: true,
          middleware: {
            auth: 'settingsRoleIndex',
          },
        },
      },
      {
        path: '/settings/role/create',
        name: 'RoleCreate',
        component: FormRole,
        meta: {
          name: 'Settings',
          requiresAuth: true,
          middleware: {
            auth: 'settingsRoleCreate',
          },
        },
      },
      {
        path: '/settings/role/edit/:roleId',
        name: 'RoleEdit',
        component: FormRole,
        meta: {
          name: 'Settings',
          requiresAuth: true,
          middleware: {
            auth: 'settingsRoleEdit',
          },
        },
      },
      {
        path: '/settings/users',
        name: 'Users',
        component: UserIndex,
        meta: {
          name: 'Settings',
          requiresAuth: true,
          middleware: {
            auth: 'settingsUserIndex',
          },
        },
      },
      {
        path: '/settings/users/adduser',
        name: 'AddUser',
        component: FormUser,
        meta: {
          name: 'Settings',
          requiresAuth: true,
          middleware: {
            auth: 'settingsUserCreate',
          },
        },
      },
      {
        path: '/settings/users/:usersid',
        name: 'EditUser',
        component: FormUser,
        meta: {
          name: 'Settings',
          requiresAuth: true,
          middleware: {
            auth: 'settingsUserEdit',
          },
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active',
})
router.beforeEach(async (to, from, next) => {
  if (
    !to.matched.some(record => record.meta.requiresAuth) &&
    store.getters.isLoggedIn
  ) {
    await store.dispatch('getCurrentAdmin')
    next('/after-login')
  } else if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !store.getters.isLoggedIn
  ) {
    alert('Kamu Perlu Login Terlebih Dahulu')
    next('/')
  } else if (store.getters.isLoggedIn) {
    await store.dispatch('getCurrentAdmin')
    if (to.meta.middleware) {
      if (!store.getters.currentAdmin.role[to.meta.middleware.auth]) {
        next('/dashboard')
      }
    }
    next()
  } else {
    next()
  }
})

export default router
