<template>
  <div class="settings">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Settings' }">
            Settings
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="columns is-multiline is-desktop">
      <div class="column is-4">
        <router-link
          :to="{ name: 'RoleIndex' }"
          v-if="
            currentAdmin.role != null && currentAdmin.role.settingsRoleIndex
          "
          class="box-router-link"
        >
          <div class="box-wrapper">
            <img
              src="../assets/settings/person_pin.png"
              alt=""
              class="box-img"
            />
            <div class="box-paragraph has-text-black py-5">
              <p class="has-text-weight-medium is-size-6">Manajemen Peran</p>
              <p
                class="has-text-primary-label-gray has-text-weight-light is-size-7"
              >
                Pengaturan Peran
              </p>
            </div>
          </div>
        </router-link>
      </div>

      <div class="column is-4">
        <router-link
          :to="{ name: 'Users' }"
          v-if="
            currentAdmin.role != null && currentAdmin.role.settingsUserIndex
          "
          class="box-router-link"
        >
          <div class="box-wrapper">
            <img
              src="../assets/settings/how_to_reg.png"
              alt=""
              class="box-img"
            />
            <div class="box-paragraph has-text-black py-5">
              <p class="has-text-weight-medium is-size-6">Manajemen Pengguna</p>
              <p
                class="has-text-primary-label-gray has-text-weight-light is-size-7"
              >
                Pengaturan Pengguna
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['currentAdmin']),
  },
}
</script>

<style>
.settings .box-wrapper {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 1rem 1rem 1rem 1rem;
  border-radius: 0.4rem;
  box-shadow: 0.2rem 0.3rem rgba(150, 150, 154, 0.2);
}
.settings .box-img {
  width: 30px;
  height: 30px;
  display: block;
  margin: auto 1.5rem auto 1.5rem;
}
.settings .box-paragraph {
  padding: 0.7rem;
}
.settings .setting-title {
  margin: 1rem 0rem 0.3rem 0rem;
  font-weight: bolder;
}
.settings .setting-info {
  opacity: 0.35;
}
.settings .box-wrapper:hover {
  background-color: rgba(48, 207, 242, 0.2);
}
.settings .box-router-link:hover {
  text-decoration: none;
}
</style>
