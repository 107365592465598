import Vue from 'vue'
import Vuex from 'vuex'
import dttot from './dttot/dttot'
import proliferasi from './proliferasi/proliferasi'
import billing from './billing/billing'
import company from './company/company'
import auth from './auth/auth'
import role from './role/role'
import user from './user/user'
import dashboard from './dashboard/dashboard'
import addon from './reporting/addon'
import addonindex from './addon/addon'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    dttot: dttot,
    proliferasi: proliferasi,
    billing: billing,
    company: company,
    auth: auth,
    role: role,
    user: user,
    addon: addon,
    addonindex: addonindex,
    dashboard: dashboard,
  },
})
