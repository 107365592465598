import axios from 'axios'
import JwtService from './jwt.service'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
  },

  setHeaderMultipartFormData() {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  },

  query(resource) {
    return axios.get(resource)
  },

  get(resource, params) {
    return axios.get(`${resource}`, {
      params: params,
      transformResponse: [
        data => {
          return camelcaseKeys(JSON.parse(data), { deep: true })
        },
      ],
    })
  },

  post(resource, params) {
    return axios.post(`${resource}`, params, {
      transformResponse: [
        data => {
          if (data) {
            return camelcaseKeys(JSON.parse(data), { deep: true })
          }
        },
      ],
    })
  },

  //sementara baru dipake untuk role
  postSnakecase(resource, params) {
    return axios.post(`${resource}`, snakecaseKeys(params, { deep: true }), {
      transformResponse: [
        data => {
          if (data) {
            return camelcaseKeys(JSON.parse(data), { deep: true })
          }
        },
      ],
    })
  },

  getFile(resource) {
    return axios.get(resource, {
      responseType: 'blob',
    })
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return axios.put(`${resource}`, params)
  },

  delete(resource) {
    return axios.delete(resource)
  },
}

export default ApiService
