import ApiService from '@/common/api.service'
// import router from '@/router'

const state = {}

const getters = {}

const actions = {
  storeExcelProliferasi(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post('/proliferasi/importexcel', credentials, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllProliferasi() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/proliferasi/getAllProliferasi').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllProliferasiIndex(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/proliferasi/getAllProliferasi?' + params).then(
        response => {
          // console.log(response.data)
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  downloadExcelProliferasi() {
    ApiService.init()
    ApiService.getFile('/proliferasi/downloadexcel')
      .then(({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'proliferasi wmd.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        throw error
      })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
