import ApiService from '@/common/api.service'
// import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getActiveCompanyClosestBilling() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/activebilling/closest-company').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getTotalActiveCompany() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/activebilling/total-active-company').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getThisMonthTotalRevenue() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/storemidtrans/this-month-revenue').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
