import ApiService from '@/common/api.service'
import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getAllRole() {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/admin/role/getAllRoleIndex').then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllRoleIndex(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/admin/role/getAllRoleIndex?' + params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  storeRole(state, rolePermissions) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.postSnakecase('/admin/role/store', rolePermissions).then(
        () => {
          router.push({ name: 'RoleIndex' })
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updateRole(state, rolePermissions) {
    console.log(rolePermissions)
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.postSnakecase(
        '/admin/role/update/' + rolePermissions.id,
        rolePermissions
      ).then(
        () => {
          router.push({ name: 'RoleIndex' })
        },
        error => {
          reject(error)
        }
      )
    })
  },
  deleteRole(state, id) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post('/admin/role/delete/' + id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getRoleByID(state, id) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/admin/role/get/' + id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
