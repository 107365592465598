import ApiService from '@/common/api.service'
import router from '@/router'

const state = {}

const getters = {}

const actions = {
  getAllAddOnsIndex(state, params) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.get('/add-on/getAll/index?' + params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  addNewAddOn(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post('/add-on/addnewaddon', credentials.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(
        () => {
          router.push('/addons')
        },
        error => {
          reject(error)
        }
      )
    })
  },
  deleteAddOn(context, credentials) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.post('/addon/delete/' + credentials).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAddOnByID(context, id) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('/add-on/' + id).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  editAddOn(context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.init()
      ApiService.post(
        '/add-on/updateaddon/' + credentials.id,
        credentials.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      ).then(
        () => {
          router.push('/addons')
        },
        error => {
          reject(error)
        }
      )
    })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
